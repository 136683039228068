import React, { Suspense, useEffect, useState, lazy } from 'react';
import { useGetButchByIdQuery } from 'src/store/services/batchApi';
import { useParams } from 'react-router';
import { useGetAssetQuery } from 'src/store/services/assetsApi';
import Loading from 'src/components/Loading';
import DownloadDocumentButton from 'src/components/DownloadDocumentButton';
import AssetsTable from 'src/components/AssetsTable';
import EventsTable from '../Events/components/EventsTable';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import CustomAccordion from '../../components/CustomAccordion';
import '../../../src/MarkdownStyles.css';
import { useTranslation } from 'react-i18next';

const ReactMarkdown = lazy(() => import('react-markdown'));

const BatchSingle = () => {
  const { t } = useTranslation('search');
  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(window.innerWidth >= 768);
  const [isBatchAccordionOpen, setIsBatchAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );

  const params = useParams<{ batch: string }>();
  const pageSize = 10;
  const { data: assetsData, isLoading: isAssetsLoading } = useGetAssetQuery({
    pageSize,
    pageOffset: currentPage,
    batchId: params.batch,
  });
  const { data: batchData, isLoading: isBatchLoading } = useGetButchByIdQuery(params.batch);

  const [gfm, setGfm] = useState<any>(null); 

  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default); 
    };

    loadGfm();
  }, []);

  if (isBatchLoading || isAssetsLoading || !gfm) {
    return <Loading />;
  }

  return (
    <>
      <div className="font-mukta-mahee text-grey-asset">
        <h1 className="font-bold text-[32px] my-[22px]">{batchData?.name}</h1>
        <p className="inline-block text-xs text-state-badge-foreground-color font-mukta-mahee tracking-tight mb-4 py-1 px-2 bg-state-badge rounded-xl">
          {t(`batch-state-${batchData?.state.toLowerCase()}-option`, {
            ns: 'batches',
          }).toUpperCase()}
        </p>
        <div className="markdown-editor">
          <Suspense fallback={<div>Loading markdown...</div>}>
            <ReactMarkdown remarkPlugins={gfm ? [gfm] : []}>
              {batchData?.description}
            </ReactMarkdown>
          </Suspense>
        </div>
        {assetsData?.assets.length && (
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title="Assets"
          >
            <AssetsTable
              data={assetsData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <DownloadDocumentButton type="batch" id={params.batch} />
          </CustomAccordion>
        )}
      </div>

      <CustomAccordion
        title={t('latest-events-title', { ns: 'batches' })}
        isAccordionOpen={isBatchAccordionOpen}
        setIsAccordionOpen={setIsBatchAccordionOpen}
      >
        <EventsTable defaultId={params.batch} defaultType={'BATCH'} pageSize={5} />
      </CustomAccordion>

      <ContextMenuModal modalType="batch" editBatchData={batchData} />
    </>
  );
};

export default BatchSingle;
