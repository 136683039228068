import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { IDocument, ITagData } from 'src/store/services/tagsApi/tagTypes';
import ExternalLinks from 'src/views/Tag/components/TagContent/components/ExternalLinks';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import EventsTable from '../../../Events/components/EventsTable';
import TagQRCode from './components/TagQRCode';
import TagWarranty from './components/TagWarranty';
import ImageNone from 'src/assets/no_img.jpg';
import ImageGallery from './components/AuthenticatedTagInfo/components/ImageGallery';
import ContextMenuModal from '../../../../components/Modals/ContextMenuModal';
import LoadDocuments from './components/AuthenticatedTagInfo/components/DocumentUploader/LoadDocuments';
import CustomAccordion from '../../../../components/CustomAccordion';
import TagDetails, { ITagStates } from './components/TagDetails';
import { useGetCategoriesQuery } from '../../../../store/services/categoryApi';
import { getLinkedProperties } from '../../../../helpers/categoriesOptions';
import '../../../../../src/MarkdownStyles.css'; 

const ReactMarkdown = lazy(() => import('react-markdown'));

interface ITagContentProps {
  data: ITagData;
}

const TagContent = ({ data }: ITagContentProps) => {
  const { t } = useTranslation('tag');
  const { isAuthenticated } = useAuth0();
  const [tagImages, setTagImages] = useState<IDocument[] | []>([]);
  const [tagDocuments, setTagDocuments] = useState<IDocument[] | []>([]);
  const [isLatestAccordionOpen, setIsLatestAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [isDocumentsAccordionOpen, setIsDocumentsAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [gfm, setGfm] = useState<any>(null);

  const { data: categoryList, isSuccess: isCategoriesSuccess } = useGetCategoriesQuery({});

  const linkedProperties = useMemo(() => {
    return getLinkedProperties(
      [...(data.categories || []), ...(data.connectedCategories || [])],
      data.properties || {},
      categoryList?.categoryTypes,
    );
  }, [categoryList?.categoryTypes, data.categories, data.connectedCategories, data.properties]);

  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default);
    };
    loadGfm();
  }, []);

  useEffect(() => {
    const { tagDocuments, tagImages } = data.documents.reduce(
      (acc: { tagDocuments: IDocument[]; tagImages: IDocument[] }, file: IDocument) => {
        if (file.documentType === 'IMAGE') {
          acc.tagImages.push(file);
        } else {
          acc.tagDocuments.push(file);
        }
        return acc;
      },
      { tagDocuments: [], tagImages: [] },
    );
    setTagImages(tagImages);
    setTagDocuments(tagDocuments);
  }, [data.documents]);

  const isMobile = window.innerWidth < 600;

  if (!gfm) return null; 

  return (
    <>
      <div className="flex flex-col w-full bg-white gap-16">
        <img
          src={tagImages[0]?.url || ImageNone}
          alt="asset hero"
          className="fixed top-0 left-0 w-full h-[45vh] object-cover z-10 md:pl-[130px]"
        />
        <div
          className="absolute top-[45vh] left-0 w-full pb-14 bg-white z-20 font-tag-font-family text-tag-font-color text-tag-font-size"
          id="asset-content"
        >
          {!!tagImages.length && !isMobile && (
            <section className="w-full mt-4 md:px-8">
              <ImageGallery assetImages={tagImages} />
            </section>
          )}
          <section className="flex w-full justify-between pt-2 md:gap-10 px-2 md:px-5 bg-logo-image bg-logo-position bg-logo-size bg-no-repeat">
            <div className="w-full h-full">
              <h5 className="text-2xl font-tag-font-family font-bold text-tag-font-color text-tag-font-size tracking-tight my-3 ">
                {data.name}
              </h5>
              <div className="markdown-editor">
                <Suspense fallback={<div>Loading markdown...</div>}>
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {data.description}
                  </ReactMarkdown>
                </Suspense>
              </div>
              <section className="flex justify-between items-center">
                <TagQRCode tag={data.tag} />
                {data.warranty && <TagWarranty expirationDate={data.warranty.expiration} />}
              </section>

              {isMobile ? (
                <div>
                  <TagDetails
                    properties={data?.properties || {}}
                    selectedCategories={data?.categories}
                    connectedCategories={data.connectedCategories}
                    location={data?.location || undefined}
                    batches={data?.batches || undefined}
                    state={data.state as ITagStates}
                    isAuthenticated={isAuthenticated}
                    categoryList={categoryList}
                    isCategoriesSuccess={isCategoriesSuccess}
                    externalIds={data?.externalIds}
                  />
                  {tagDocuments.length || linkedProperties.length || data?.externalIds.length ? (
                    <CustomAccordion
                      isAccordionOpen={isDocumentsAccordionOpen}
                      setIsAccordionOpen={setIsDocumentsAccordionOpen}
                      title={t('documents-and-links-title')}
                      accordionWrapperClassName="font-tag-font-family text-tag-font-color"
                    >
                      <LoadDocuments
                        tagDocuments={tagDocuments}
                        linkedProperties={linkedProperties}
                        externalIds={data.externalIds}
                      />
                    </CustomAccordion>
                  ) : null}

                  {isAuthenticated && data?.id && (
                    <CustomAccordion
                      isAccordionOpen={isLatestAccordionOpen}
                      setIsAccordionOpen={setIsLatestAccordionOpen}
                      title={t('latest-changes')}
                      accordionWrapperClassName="pb-16 font-tag-font-family text-tag-font-color"
                    >
                      <EventsTable
                        defaultId={String(data?.id)}
                        defaultType={'ASSET'}
                        pageSize={5}
                        rowClassNames="font-tag-font-family text-tag-font-color"
                      />
                    </CustomAccordion>
                  )}
                </div>
              ) : (
                <div className="accordion-tag-details-container">
                  <section className="tag-details-item">
                    <TagDetails
                      properties={data?.properties || {}}
                      selectedCategories={data?.categories}
                      connectedCategories={data.connectedCategories}
                      location={data?.location || undefined}
                      batches={data?.batches || undefined}
                      state={data.state as ITagStates}
                      isAuthenticated={isAuthenticated}
                      categoryList={categoryList}
                      isCategoriesSuccess={isCategoriesSuccess}
                      externalIds={data?.externalIds}
                    />
                  </section>

                  <section className="accordion-item">
                    {tagDocuments.length || linkedProperties.length || data?.externalIds.length ? (
                      <CustomAccordion
                        isAccordionOpen={isDocumentsAccordionOpen}
                        setIsAccordionOpen={setIsDocumentsAccordionOpen}
                        title={t('documents-and-links-title')}
                        accordionWrapperClassName="font-tag-font-family text-tag-font-color"
                      >
                        <LoadDocuments
                          tagDocuments={tagDocuments}
                          linkedProperties={linkedProperties}
                          externalIds={data.externalIds}
                        />
                      </CustomAccordion>
                    ) : null}

                    {isAuthenticated && data?.id && (
                      <CustomAccordion
                        isAccordionOpen={isLatestAccordionOpen}
                        setIsAccordionOpen={setIsLatestAccordionOpen}
                        title={t('latest-changes')}
                        accordionWrapperClassName="pb-16 font-tag-font-family text-tag-font-color"
                      >
                        <EventsTable
                          defaultId={String(data?.id)}
                          defaultType={'ASSET'}
                          pageSize={5}
                          rowClassNames="font-tag-font-family text-tag-font-color"
                        />
                      </CustomAccordion>
                    )}
                  </section>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <ContextMenuModal modalType="asset" editAssetData={data} setLocalTagImages={setTagImages} />
    </>
  );
};

export default React.memo(TagContent);
