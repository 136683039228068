import React, { useEffect } from 'react';
import { useFormik, FormikProps } from 'formik';
import FormField from 'src/components/FormField';
import { IEditAssetBodyData } from 'src/store/services/assetsApi/assetsTypes';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';
import FormWrapper from 'src/components/FormWrapper';
import { TagValidation } from './tagValidation';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import BatchesSelect from './components/BatchesSelect';
import LocationSelect from './components/LocationSelect';
import CategoriesSelect from './components/CategoriesSelect';
import TagStateSelect from './components/TagStateSelect';
import { getTagInitialValues } from './getTagInitialValues';
import { ServerError } from 'src/store/storeTypes';
import ServerFormError from 'src/components/ServerFormError';
import ExternalIdsMultiselect from './components/ExternalIdsMultiselect';
import CategoryPropertyList from "./components/CategoryPropertyList";
import CustomMDEditor from 'src/components/MDEditor';

interface ITagForm {
  title: string;
  onSubmit: any;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  isOpened: boolean;
  error: any;
  data?: ITagData;
  onClose: () => void;
  resetEditAsset?: () => void;
}

const TagForm = ({
  title,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  onClose,
  resetEditAsset,
  data,
}: ITagForm) => {
  const { t } = useTranslation('tag');

  const initialValues = getTagInitialValues(data);

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
    submitForm,
  }: FormikProps<IEditAssetBodyData> = useFormik<IEditAssetBodyData>({
    onSubmit: (values) => {
      const batchesIds = values?.batches?.length && values.batches.map((batch) => batch.id);
      const newAssetBody = {
        name: values.name,
        description: values.description,
        state: values.state,
        categories: values.categories,
        locationId: values?.location?.id,
        properties: values.properties,
        ...(values.batches?.length ? { batches: batchesIds } : {}),
        ...(values.properties ? { properties: values.properties } : {}),
        ...(!values.externalIds?.length ? { deleteFields: ['externalIds'] } : {}),
      };
      onSubmit({
        body: newAssetBody,
        assetId: data?.id,
        ...getXAuthOwnerHeader(values.organization),
      });
    },
    initialValues,
    validationSchema: TagValidation(),
  });

  useEffect(() => {
    if (isSuccess) {
      onClose();
      resetEditAsset && resetEditAsset();
    }
  }, [isSuccess, onClose, resetEditAsset]);

  return (
    <ModalContainer isModalOpened={isOpened} onClose={onClose} isFormModal={true}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={handleSubmit}>
        <div className="w-[350px] md:w-[650px] min-h-[300px] pb-[40px]">
          <FormField
            type="text"
            value={values.name}
            name={'name'}
            placeholder={t('edit_tag_name_placeholder')}
            onChange={handleChange}
            isError={!!errors.name && !!touched.name}
            errorMessage={(touched.name && errors?.name) || ''}
          />
          <div data-color-mode="light" className="markdown-editor mb-8">
            <CustomMDEditor
              id="description-editor"
              label={t('edit_tag_description_placeholder')}
              value={values.description || ''}
              onChange={(value) => handleChange({ target: { name: 'description', value } })}
            />
          </div>
          {touched.description && errors.description && (
            <div className="error-message">{errors.description}</div>
          )}
          <div className="flex flex-col">
            <TagStateSelect state={values.state} setFieldValue={setFieldValue} />
            <BatchesSelect batches={values.batches} setFieldValue={setFieldValue} />
            <LocationSelect location={values.location} setFieldValue={setFieldValue} />
            <CategoriesSelect categories={values.categories} setFieldValue={setFieldValue} />
            <ExternalIdsMultiselect tagData={data} setFieldValue={setFieldValue}
              placeholder={t('select-tags-placeholder')} />
            <CategoryPropertyList values={values} setFieldValue={setFieldValue} />
          </div>
          <OrganizationsSelect
            setFieldValue={setFieldValue}
            value={values.organization}
            isError={!!errors.organization && !!touched.organization}
            errorMessage={(touched.organization && errors?.organization) || ''}
            placeholder={t('select-organization-placeholder')}
          />

          <div className="flex justify-center mt-4">
            <SaveButton submitFunc={submitForm} disabled={isLoading} />
            <CloseButton onClose={onClose} disabled={isLoading} />
          </div>

          <ServerFormError
            title={t('error-request')}
            requestError={error as ServerError}
            isError={isError}
          />
        </div>
      </FormWrapper>
    </ModalContainer>
  );
};

export default React.memo(TagForm);
